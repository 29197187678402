import React, { useEffect, useState } from 'react'
import './home.scss'
import OrderCard from '../../components/orderCard/orderCard'
import api from '../../api'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';
import { useNavigate } from 'react-router-dom'
import ProductPage from '../../components/pagenetion/pagenetion'

i18n.use(initReactI18next).init({
  resources: {
    uz: { translation: translateUz },
    ru: { translation: translateRu }
  },
  lng: 'uz',
  fallbackLng: 'uz'
})

const Home = () => {
  const [products, setProducts] = useState([])



  async function getUser() {
    const endPoint = `/product_${i18n.language}`
    try {
      const response = await api.get(endPoint)
      setProducts(response.data?.[`Product_${i18n.language}`]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUser()
  }, [i18n.language])

  const navigateCompany = useNavigate();
  const navigateInformation = useNavigate();
  const navigateEstrozed = useNavigate();
  const navigateAndrozed = useNavigate();
  const { t } = useTranslation()

  const handleNavigationCompany = () => {
    navigateCompany('/okompani');
  };

  const handleNavigationInform = () => {
    navigateInformation('/kontakt');
  };

  const handleNavigatiEstrozed = () => {
    navigateEstrozed('/estrozed');
  };

  const handleNavigatiAndrozed = () => {
    navigateAndrozed('/androzed');
  };

  return (
    <>
      <div className='header'>
        <div className="container">
          <h1 className='header_title text-light'>{t("headerTitle")}</h1>
          <h2 className='header_replase_title'>OOO "PRO-ZED"</h2>
          <p className='header_description text-light mb-5'>{t("headerDescription")}</p>
          <a style={{ textDecoration: 'none' }} href="#checimal"><button className='header_btn'>{t("headerBtn")}</button></a>
        </div>
      </div>
      {/* hero */}
      <div className="hero">
        <div className="container pt-4">
          <div className="hero_section">
            <div className="hero_main_foto">
              <div className="hero_img">
                <img style={{ width: "100%", height: 'auto' }} className="hero_main_img" src="../images/pages/Site_1.png" alt="Androzed" />
              </div>
            </div>
            <div className="hero_body">
              <div className="hero_img">
                <img style={{ width: "100%", height: 'auto' }} className="hero_main_img" src="../images/pages/Site_2.png" alt="Estrozed" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className="home_estrozed">
        <div className='container'>
          <div className="main_chamical">
            <div className="main_chamical_estrozed">
              <img className='main_chamical_estrozed_img' src="../images/pages/androzed.png" alt="Androzed" />
              <p className='main_chamical_title_androzed'>{t("androzed")}</p>
              <ul>
                <li className='main_chamical_desctiption'>{t("normalizesMenopause")}</li>
                <li className='main_chamical_desctiption'>{t("normalizesEndometrial")}</li>
                <li className='main_chamical_desctiption'>{t("bodyVitamins")}</li>
                <li className='main_chamical_desctiption'>{t("bodyVitamins")}</li>
                <li className='main_chamical_desctiption'>{t("improvesCondition")}</li>
                <li className='main_chamical_desctiption'>{t("improvesFemaleBody")}</li>
              </ul>
              <button onClick={handleNavigatiAndrozed} className='main_chamical_btn'>{t("informProduct")}</button>

            </div>
            <div className="main_chamical_estrozed">
              <img className='main_chamical_estrozed_img' src="../images/pages/estrozed 1.png" alt="Estrozed" />
              <p className='main_chamical_title_estrozed'>{t("estrozed")}</p>
              <ul>
                <li className='main_chamical_desctiption'>{t("sleepQuality")}</li>
                <li className='main_chamical_desctiption'>{t("excitabilityOfSperm")}</li>
                <li className='main_chamical_desctiption'>{t("excitabilityOfSperm")}</li>
                <li className='main_chamical_desctiption'>{t("spermCount")}</li>
                <li className='main_chamical_desctiption'>{t("supplementDepression")}</li>
              </ul>
              <button onClick={handleNavigatiEstrozed} className='main_chamical_btn'>{t("informProduct")}</button>
            </div>
          </div>
        </div>
      </div>
      <ProductPage data={products} type="products" />
      {/* products */}
      <div className="container">
        <h1 className='what_is_bady'>{t("whatIsBad")}</h1>
        <div className="what_is_bady_inform">
          <p className="what_is_bady_inform_description">{t("whatIsBadDescription")}</p>
        </div>
      </div>
      {/* recomendatcy */}
      {/* <ExpertRecommendations experts={experts} /> */}
      {/* about prozed */}
      <div className="prozed">
        <div className="container">
          <div className="prozed_section">
            <div className="prozed_info">
              <p className='prozed_info_title'>PRO-ZED</p>
              <p className='prozed_info_description'>{t("aboutProzed")}</p>
              <p className='prozed_info_description'>{t("originCompany")}</p>
              <p className='prozed_info_description'>{t("drivenDeep")}</p>
              <p className='prozed_info_description'>{t("PROZEDLLC")}</p>
              <button onClick={handleNavigationCompany} className='prozed_info_btn'>{t("btnocompany")}</button>
            </div>
            <div className="prozed_img">
              <img style={{ width: "100%" }} src="./images/pages/Vector (1).png" alt="" />
            </div>
          </div>

        </div>
        {/* prozed deliver */}
      </div>
      <div className="container">
        <div className="prozed_deliver">
          <p className='prozed_deliver_title'>{t("freeDeliver")}</p>
          <button onClick={handleNavigationInform} className='prozed_deliver_btn'>{t("knowMoreBtn")}</button>
        </div>
      </div>
      <div className='order_btn'>
        <OrderCard />
      </div>
    </>
  )
}

export default Home
