import React from 'react'
import './ocompany.scss'
import OrderCard from '../../components/orderCard/orderCard'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';
import ProductPage from '../../components/pagenetion/pagenetion';

i18n.use(initReactI18next).init({
  resources: {
    uz: { translation: translateUz },
    ru: { translation: translateRu }
  },
  lng: 'uz',
  fallbackLng: 'uz'
})
const sertificates = [
  {
    img: "./images/sertificates/sertificate.prozed.jpg",
  },
  {
    img: './images/sertificates/sertificate1.jpg'
  },
  {
    img: './images/sertificates/sertificate2.jpg'
  },
  {
    img: './images/sertificates/sertificate3.jpg'
  },
  {
    img: './images/sertificates/sertificate4.jpg'
  },
  {
    img: './images/sertificates/sertificate6.jpg'
  }
]
const partnors = [
  {
    img: "./images/partnors/Akmalfarm.jpg",
  },
  {
    img: "./images/partnors/pharmacy.jpg",
  },
  {
    img: "./images/partnors/visus.jpg"
  },
  {
    img: "./images/partnors/dorimarket.jpg"
  },
  {
    img: "./images/partnors/nargisClinik.jpg"
  },
  {
    img: "./images/partnors/makrofarm.jpg"
  },
  {
    img: "./images/partnors/gulnorafarm.jpg"
  },
  {
    img: "./images/partnors/makrofarm.jpg"
  },
]


const Ocompany = () => {

  
  const { t } = useTranslation()
  return (
    <div className="about_company_page">
      <div className="about_company">
        <div className='container'>
          <h2 className='about_company_name'>PRO-ZED</h2>
          <p className='about_company_title'>{t("биологическиAктивных")}</p>
          <p className='about_company_origenate'>{t("aboutCompany")}</p>
          <p className='about_company_description'>{t("aboutCompanyDescription")}</p>

          <h2 className='about_company_achievement'>{t("ourAchievement")}</h2>

          <div className="d-flex gap-5 about_company_list">
            <div className="about_company_inform">
              <p className='about_company_ages'>{t("companyAge")}</p>
              <p className='about_company_opened'>{t("sinceOpened")}</p>
            </div>
            <div className="about_company_inform">
              <p className='about_company_ages'>2700</p>
              <p className='about_company_opened'>{t("achievedResalts")}</p>
            </div>
            <div className="about_company_inform">
              <p className='about_company_ages'>12000</p>
              <p className='about_company_opened'>{t("boughtProducts")}</p>
            </div>
          </div>
          {/* <h4 className='about_company_sertificate'>{t("certificates")}</h4> */}

          {/* <ProductPage data={sertificates} type="sertificates" /> */}

        </div>
      </div>
      <h4 className='our_partner'>{t("ourPartners")}</h4>
      <div className=' d-flex gap-5 mb-5 p-3 our_partner_list'>
        {
          partnors.map((item, index) => (
            <img src={item.img} alt={`Partner ${index}`} className='our_partner_item' />
          )
          )}
      </div>
      <OrderCard />
    </div>


  )
}

export default Ocompany
